import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const Section = makeShortcode("Section");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-is-luna",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-luna",
        "aria-label": "what is luna permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`What is Luna?`}</h2>
    <p>{`Luna is the design system for Sainsbury’s. It also supports Argos, Habitat, Tu, Nectar, and Sainsbury's Bank.`}</p>
    <p>{`It’s made up of 3 elements:`}</p>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Guidelines website" titleElement="h3" border mdxType="GridListItem">
    A single source of truth for designers, developers and external agencies
    working with Sainsbury’s.
  </GridListItem>
  <GridListItem title="Codebase" titleElement="h3" border mdxType="GridListItem">
    A growing resource of foundation code and reusable components.
  </GridListItem>
  <GridListItem title="Design assets" titleElement="h3" border mdxType="GridListItem">
    A Figma UI library, icon set and other assets that reflect what’s in the
    code.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "why-use-luna",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-use-luna",
        "aria-label": "why use luna permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Why use Luna?`}</h2>
    <p>{`By using Luna, teams don’t have to design and build things that have been designed and built already.`}</p>
    <p>{`Luna provides things that can be re-used, whether that's a component like a button or a guideline that governs how that button should be used. We make sure that the things we provide are built to a high standard and accessible for all users.`}</p>
    <p>{`That means teams can go faster, and users get a better, more consistent experience.`}</p>
    <h3>{`Luna's purpose is:`}</h3>
    <Section mdxType="Section">
  <GridList columns="3" mdxType="GridList">
    <GridListItem title="To provide" titleElement="h4" filled mdxType="GridListItem">
      Guidelines, resources and documentation
    </GridListItem>
    <GridListItem title="so that" titleElement="h4" filled mdxType="GridListItem">
      teams can create high quality, consistent and accessible user experiences
    </GridListItem>
    <GridListItem title="for" titleElement="h4" filled mdxType="GridListItem">
      customers, colleagues and suppliers, across all our brands.
    </GridListItem>
  </GridList>
    </Section>
    <hr></hr>
    <h2 {...{
      "id": "accessibility-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility-principles",
        "aria-label": "accessibility principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Accessibility principles`}</h2>
    <p>{`Accessibility is one the foundations of Luna, but using Luna doesn’t guarantee complete accessibility all the time.`}</p>
    <p>{`Our accessibility principles are to help designers and developers understand the main considerations when building accessible products.`}</p>
    <p><a parentName="p" {...{
        "href": "/accessibility"
      }}>{`Read our accessibility principles`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "where-to-go-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#where-to-go-next",
        "aria-label": "where to go next permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Where to go next`}</h2>
    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Getting started for designers" titleElement="h3" linkText="Start designing" linkTo="/getting-started/for-designers" border icon="designer" mdxType="GridListItem">
    Find out how to start designing using Luna.
  </GridListItem>
  <GridListItem title="Getting started for developers" titleElement="h3" linkText="Start developing" linkTo="/getting-started/for-developers" border icon="developer" mdxType="GridListItem">
    What to do to start building using Luna.
  </GridListItem>
  <GridListItem title="Getting started for third party partners" titleElement="h3" linkText="Start designing and developing" linkTo="/getting-started/for-third-party-partners" border icon="third-party" mdxType="GridListItem">
    How to start using Luna if you're a third party partner.
  </GridListItem>
    </GridList>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">We can help get started on your next project.</GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      